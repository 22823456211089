

<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<app-dialog-force-create [installmentAmount]="this.newInstallmentAmount" [installmentNumber]="this.newInstallmentNumber" #dialogForceCreate (close)="acceptNewPlan($event)"></app-dialog-force-create>
<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div>
        <h1>Benvenuto in APPpago</h1>
        <h2>Lo strumento innovativo che ti permette di dilazionare i tuoi acquisti senza costi aggiuntivi!</h2>
      </div>
    </div>
  </section>
    <section class="home">
      <div class="multitab">
        <div>
          <div class="options">
            <div class="step-one">
              <div>
                <h3>Fai la tua scelta</h3>
                  <p>Scegli il metodo di pagamento e tieni pronti il codice IBAN o quello della tua carta</p>
                </div>
            </div>
            <div class="step-two">
              <div>
                <h3>Carica i tuoi documenti</h3>
                 <p>Tieni a portata di mano il tuo documento d'identità e la tua tessera sanitaria</p>
              </div>
            </div>
            <div class="step-three">
              <div>
                <h3>Terminato!</h3>
                 <p>Riceverai una mail riepilogativa con tutti i dettagli della dilazione</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="maintenance" *ngIf="onboarding.checkMaintenance != ''">
        {{ onboarding.checkMaintenance }}
      </div>
  </section>
  <section class="commands">
    <div class="wrap">
      <div class="cmd">
        <button class="btn tertiary" (click)="next()" [disabled]="!enabledButton">Inizia</button>
      </div>
    </div>
  </section>
</ng-container>
